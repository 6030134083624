module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-jss/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[],"hoist":false,"sourceMap":false,"autoLabel":false,"labelFormat":"[local]","extractStatic":false,"outputDir":"","importedNames":{"styled":"styled","css":"css","keyframes":"keyframes","injectGlobal":"injectGlobal","merge":"merge"}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-142205296-1","head":true},
    }]
